/* eslint no-param-reassign: ["error", { "props": false }] */
const tableMixin = {
  data() {
    return {
      headers: [], // override in component
      tableRowKey: 'id', // override if it's different
      tableMixin_expandedRows: [],
    };
  },

  computed: {
    tableMixin_displayedHeaders() {
      const displayedHeaders = this.headers.filter(h => !this.$vuetify.breakpoint[h.hidden]);
      if (this.hasActions) { // should be defined in props
        displayedHeaders.push({ value: 'actions', align: 'end' });
      }
      return displayedHeaders;
    },

    tableMixin_hiddenHeaders() {
      return this.headers.filter(h => this.$vuetify.breakpoint[h.hidden]);
    },

    tableMixin_firstExpandedRowKey() {
      return this.tableMixin_expandedRows.length
        ? this.tableMixin_expandedRows[0][this.tableRowKey]
        : null;
    },
  },

  methods: {
    tableMixin_onRowClick(item) {
      if (this.tableMixin_hiddenHeaders.length) {
        this.tableMixin_expandedRows = this.tableMixin_firstExpandedRowKey
        !== item[this.tableRowKey]
          ? [item]
          : [];
      } else {
        this.$emit('row-click', item);
      }
    },

    tableMixin_changePage(page) {
      if (page !== this.pagination.page) {
        this.$emit('change-page', page);
      }
    },
  },
};

export default tableMixin;
