<template>
  <div class="d-flex justify-end">
    <v-tooltip
      v-for="action in buttonActions"
      :key="action.label"
      open-delay="500"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          @click.stop="onActionClick(action, $event)"
        >
          <v-icon>{{ action.icon }}</v-icon>
        </v-btn>
      </template>
      <span>
        {{ action.label }}
      </span>
    </v-tooltip>
    <v-menu
      v-if="menuActions.length"
      offset-y
      z-index="10"
    >
      <template v-slot:activator="{ on: onMenu }">
        <v-tooltip
          v-on="onMenu"
          open-delay="500"
          bottom
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              v-on="{ ...onMenu, ...onTooltip }"
              class="js-actions-menu-activator"
              icon
              @click.stop
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t('more_actions') }}
          </span>
        </v-tooltip>
      </template>
      <slot>
        <v-list>
          <v-list-item
            v-for="action in menuActions"
            :key="action.label"
            @click="onActionClick(action, $event)"
          >
            <v-list-item-icon>
              <v-icon v-if="action.icon">
                {{ action.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ action.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'BaseActionMenu',

  props: {
    actions: {
      type: Array,
      default: () => [],
    },

    item: {
      type: [Object, null],
      default: null,
    },
  },

  computed: {
    buttonActions() {
      if (!this.actions.length) {
        return [];
      }
      return this.actions.length < 3 ? this.actions : this.actions.slice(0, 1);
    },

    menuActions() {
      if (this.actions.length < 3) {
        return [];
      }
      return this.actions.length < 3 ? [] : this.actions.slice(1);
    },
  },

  methods: {
    onActionClick(action, domEvent) {
      const payload = this.item ? this.item : domEvent;
      action.callback(payload);
    },
  },
};
</script>
